import { useNavigate, useLocation } from 'react-router';
import Typography from '@mui/material/Typography';
import Icon from 'components/system/Icon';
import ColoredButton from 'components/system/ColoredButton';
import colors from 'styles/colors';

const BackButton = ({ navigateToParent, backTo, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    if (backTo) {
      navigate(backTo);
      return;
    }
    if (navigateToParent) {
      const currentPath = location.pathname;
      const parentPath = currentPath.split('/').slice(0, -1).join('/') || '/';
      navigate(parentPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <ColoredButton onClick={handleClick} {...props}>
      <Icon
        size="14px"
        name="arrow_back"
        color={colors.text.primary}
      />
      <Typography variant="label" component="span" sx={{ marginLeft: '10px' }}>
        Back
      </Typography>
    </ColoredButton>
  );
};

export default BackButton;
