import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { compose } from 'ramda';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Logo from 'assets/svgIcons/logo.svg';
import { auth, useAuthState } from 'api/services/firebase';
import AccountAccess from 'components/pages/Account/AccountAccess';
import { ApiKeyButton } from 'components/system/CustomizedButton';
import Divider from 'components/system/Divider/Divider';
import withSignUpDialog from 'components/hoc/withSignUpDialog';
import withSignInDialog from 'components/hoc/withSignInDialog';
import useAccountStore from 'store/accountStore';
import AccountModeSwitch from './AccountModeSwitch';
import NotebookSection from './NotebookSection';

const Header = ({
  isVerified, isErrored, openSignUpDialog, openSignInDialog,
}) => {
  const [showHybridButton, setShowHybridButton] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const isGuest = !user;
  const isTeam = useAccountStore((state) => state.activeAccount?.teamId);

  useEffect(() => {
    if (location.pathname === '/hybrid-jobs-notebook' && isTeam) {
      setShowHybridButton(true);
    } else {
      setShowHybridButton(false);
    }
  }, [location]);

  return (
    <>
      <Box
        id="appHeader"
        sx={{
          height: '80px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          paddingLeft: '20px',
          paddingRight: '32px',
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                  <Logo />
                </Box>
              </Grid>
              { showHybridButton && (
                <NotebookSection />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              {isGuest ? (
                <>
                  <Grid item>
                    <Button
                      sx={{ marginTop: '4px' }}
                      onClick={() => openSignInDialog()}
                      variant="text"
                    >
                      Log in
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => openSignUpDialog()}
                      variant="outlined"
                      type="secondary"
                    >
                      Sign up for Free
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  {isVerified && !isErrored && user && (
                    <Grid item><ApiKeyButton /></Grid>
                  )}
                  {process.env.TEAM_ACCOUNTS === 'true' && isVerified && !isErrored && user && (
                    <Grid item><AccountModeSwitch /></Grid>
                  )}
                  <Grid item sx={{ paddingLeft: '24px' }}>
                    <AccountAccess
                      isErrored={isErrored}
                      isVerified={isVerified}
                      name={user.displayName}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </>
  );
};

export default compose(withSignUpDialog, withSignInDialog)(Header);
