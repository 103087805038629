import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colors from 'styles/colors';
import BackButton from '../BackButton';

const Page = ({
  children, withBackButton, backTo, title, subTitle, sx,
}) => (
  <Container
    id="container"
    sx={{
      padding: '0 0 40px 0 !important',
      width: '100%',
      ...sx,
    }}
  >
    {withBackButton || title || subTitle
      ? (
        <Box
          sx={{
            paddingTop: '40px',
            paddingBottom: '24px',
            background: '#060E1F',
            zIndex: 1000,
            position: 'sticky',
            top: 0,
          }}
          className="page-header__container"
        >
          {withBackButton && (
            <Box
              sx={{
                width: '100%',
                marginBottom: title ? '24px' : 0,
              }}
            >
              <BackButton navigateToParent backTo={backTo} />
            </Box>
          )}
          {title && (
            <Typography
              variant="h4"
              sx={{
                color: colors.text.primary,
              }}
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              variant="subtitle1"
              sx={{
                color: colors.text.secondary,
                marginTop: '8px',
              }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      ) : null}
    {children}
  </Container>
);

export default Page;
