import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from 'styles/colors';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import './headerBanner.scss';

const styles = {
  announcement: (view) => ({
    zIndex: '1',
    fontSize: view === 'mobile' ? '10px' : '18px',
    lineHeight: '18px',
    fontWeight: '500',
  }),
  note: {
    zIndex: '1',
  },
  link: (view) => ({
    fontSize: view === 'mobile' ? '10px' : '18px',
    lineHeight: '18px',
    fontWeight: '900',
    color: colors.text.primary,
    cursor: 'pointer',
  }),
  banner: (view, isAnimated) => ({
    height: view === 'mobile' ? '30px' : '40px',
    gridColumnGap: '4px',
    gridRowGap: '4px',
    overflow: 'hidden',
    marginBottom: '0',
    position: 'relative',
    width: '100%',
    opacity: isAnimated ? 1 : 0,
    transform: isAnimated ? 'translateY(0)' : 'translateY(-100%)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
  }),
  background: {
    backgroundImage: 'linear-gradient(90deg, #0060fa, #605ef5 60%)',
  },
  border: (view) => ({
    display: 'flex',
    padding: view === 'mobile' ? '5px 0' : '10px 0',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
};

const HeaderBanner = ({
  announcement,
  note,
  callForAction,
  actionHandler,
  actionType = 'button',
  view = 'desktop',
  hasCloseIcon = false,
  excludePaths = [],
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const [isAnimated, setIsAnimated] = useState(false);
  const location = useLocation();
  const isExcluded = excludePaths.some((path) => location.pathname.includes(path));

  const handleBannerToggle = () => {
    // Start the exit animation
    setIsAnimated(false);

    // Wait for animation to complete before closing
    setTimeout(() => {
      setIsOpened(false);
    }, 500); // Match this with the transition duration
  };

  useEffect(() => {
    const appElement = document.getElementById('app');
    if (appElement) {
      if (isOpened) {
        appElement.classList.add('HeaderBanner');
      } else {
        appElement.classList.remove('HeaderBanner');
      }
    }

    return () => {
      appElement?.classList.remove('HeaderBanner');
    };
  }, [isOpened]);

  // Animation effect on mount
  useEffect(() => {
    if (isOpened && !isExcluded) {
      // Small delay to ensure the element is in the DOM
      setTimeout(() => {
        setIsAnimated(true);
      }, 10);
    }
    return () => {
      setIsAnimated(false);
    };
  }, [isOpened, isExcluded]);

  if (!isOpened || isExcluded) {
    return null;
  }

  return (
    <Box sx={styles.banner(view, isAnimated)} id="headerBanner">
      <Box sx={styles.background}>
        <Box sx={styles.border(view)}>
          <Box>
            <Typography component="div" sx={styles.announcement(view)} color={colors.text.primary}>
              {announcement}
              &nbsp;
            </Typography>
            {note && (
              <Typography variant="helper" sx={styles.note} color={colors.text.secondary}>
                {note}
              </Typography>
            )}
          </Box>
          {actionType === 'button' ? (
            <Button
              sx={{ minWidth: '200px', cursor: 'pointer' }}
              type="primary"
              variant="contained"
              onClick={actionHandler}
            >
              {callForAction}
            </Button>
          ) : (
            <>
              &nbsp;
              <Link
                href={actionHandler}
                sx={{
                  ...styles.link(view),
                  textDecoration: 'underline',
                }}
              >
                {callForAction}
              </Link>
              &nbsp;
              <Typography sx={{ ...styles.link(view), fontWeight: '500' }}>→</Typography>
            </>
          )}
        </Box>
      </Box>
      {hasCloseIcon ? (
        <CloseIcon
          fontSize="16px"
          htmlColor={colors.text.primary}
          sx={styles.closeIcon}
          onClick={handleBannerToggle}
        />
      ) : null}
    </Box>
  );
};

export default HeaderBanner;
