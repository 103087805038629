import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SignIn from './SignIn';
import SignUp from './SignUp';

const views = {
  signIn: {
    component: SignIn,
    linkedComponent: 'signUp',
  },
  signUp: {
    component: SignUp,
    linkedComponent: 'signIn',
  },
};

const setTitle = (isMobile, backUrl, currentView) => {
  const containsHackathons = (url) => url.includes('hackathons');

  if (isMobile && containsHackathons(backUrl)) {
    const externalLink = 'https://www.bluequbit.io/quantum-computing-hackathon';
    return currentView === 'signUp' ? (
      <Typography>
        Sign Up to join the
        {' '}
        <Link
          href={externalLink}
        >
          hackathon
        </Link>
      </Typography>
    ) : (
      <Typography>
        Sign In to join the
        {' '}
        <Link
          href={externalLink}
        >
          hackathon
        </Link>
      </Typography>
    );
  }
  return '';
};

const AuthForm = ({
  view, isMobile, backUrl, ...rest
}) => {
  const [currentView, setCurrentView] = useState(view);
  const { component: Component, linkedComponent } = views[currentView];

  return (
    <Component
      {...rest}
      title={setTitle(isMobile, backUrl, currentView)}
      redirectToLinkedView={() => setCurrentView(linkedComponent)}
    />
  );
};

export default AuthForm;
