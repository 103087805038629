import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from 'styles/colors';
import Icon from 'components/system/Icon';
import { auth, useAuthState } from 'api/services/firebase';
import useAccountStore from 'store/accountStore';

const styles = {
  container: {
    borderRight: 1,
    display: 'flex',
    borderColor: colors.border.border_10p,
    justifyContent: 'center',
    height: '100%',
    width: '175px',
    padding: '40px 0',
  },
  menuList: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    paddingTop: '0px',
  },
  menuItem: {
    display: 'flex',
    width: '143px',
    height: '18px',
    padding: '16px',
    color: colors.text.tetriary,
    borderRadius: '16px',
    margin: '0 16px 0 13px',

  },
  selectedMenuItem: {
    background: colors.background.secondary,
    color: colors.text.primary,
  },
  selectedMenuBar: {
    borderRadius: '0px 24px 24px 0px',
    background: 'linear-gradient(259.41deg, #0060FA -27.53%, #CC74F5 126.63%)',
  },
  selectedMenuBarHidden: {
    height: '36px',
    width: '3px',
    display: 'inline-block',
  },
};

const Navigation = () => {
  const location = useLocation();
  const navigatedPathKey = location.pathname.split('/')[1] || 'home';
  const [selectedMenu, setSelectedMenu] = useState(navigatedPathKey);

  const [user] = useAuthState(auth);
  const isTeam = useAccountStore((state) => state.activeAccount.teamId);
  const userEmail = useAccountStore((state) => state.account.email);

  const leftMenuSettings = useMemo(() => {
    const menu = [
      {
        icon: 'home',
        key: 'home',
        url: '/',
        label: 'Home',
      },
      {
        icon: 'statistics',
        key: 'jobs',
        url: '/jobs',
        label: 'Jobs',
      },
      {
        icon: 'document',
        key: 'docs',
        url: '/docs',
        label: 'Docs',
      },
      {
        icon: 'hybrid_icon',
        key: 'hybrid-jobs-notebook',
        url: '/hybrid-jobs-notebook',
        label: 'Hybrid',
        isHidden: !user || !isTeam,
      },
    ];

    if (process.env.COMPETITIONS === 'true') {
      menu.push({
        icon: 'competitions',
        key: 'hackathons',
        url: '/hackathons',
        label: 'Hackathons',
      });
    }

    return menu;
  }, [user, isTeam]);

  useEffect(() => {
    if (navigatedPathKey !== selectedMenu) {
      setSelectedMenu(navigatedPathKey);
    }
  }, [navigatedPathKey, selectedMenu]);

  const navigate = useNavigate();

  const handleMenuItemClick = (key, url) => () => {
    navigate(url);
    setSelectedMenu(key);
  };

  return (
    <Box sx={styles.container}>
      <MenuList sx={styles.menuList}>
        {
          leftMenuSettings.map(({
            icon, key, url, label, isHidden,
          }) => (
            isHidden ? null
              : (
                <MenuItem
                  key={key}
                  onClick={handleMenuItemClick(key, url)}
                  sx={{ padding: 0 }}
                >
                  <Box
                    sx={{
                      ...styles.selectedMenuBarHidden,
                      ...(selectedMenu === key ? styles.selectedMenuBar : {}),
                    }}
                  />
                  <Box
                    sx={{
                      ...styles.menuItem,
                      ...(selectedMenu === key ? styles.selectedMenuItem : {}),
                    }}
                  >
                    {icon && (
                      <Box sx={{ width: '18px', height: '18px', alignItems: 'start' }}>
                        <Icon
                          size="18px"
                          name={icon}
                          sx={{ verticalAlign: 'inherit' }}
                          color={(selectedMenu === key
                            ? colors.text.primary
                            : colors.text.tetriary
                          )}
                        />
                      </Box>
                    )}
                    <Typography
                      component="span"
                      sx={{
                        marginLeft: '8px',
                        fontWeight: 600,
                        verticalAlign: 'middle',
                        lineHeight: '18px',
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                </MenuItem>
              )
          ))
        }
      </MenuList>
    </Box>
  );
};

export default Navigation;
