import { Helmet } from 'react-helmet-async';
import seoPageData, { defaultData } from 'constants/seo';
import { useEffect, useRef } from 'react';

const withSeo = (WrappedComponent) => (props) => {
  const initialPathRef = useRef(window.location.pathname);

  const getPageKeyFromUrl = () => {
    const path = window.location.pathname;
    if (path === '/docs') {
      return 'DocsPage';
    }
    if (path === '/sign-up') {
      return 'SignUpPage';
    }
    if (path === '/jobs') {
      return 'JobsPage';
    }
    if (path.startsWith('/hackathons')) {
      return 'HackathonPage';
    }
    return 'default';
  };

  const pageKey = getPageKeyFromUrl();
  const {
    title,
    description,
    ogTitle,
    ogDescription,
    ogType,
    ogImage,
    ogUrl,
    canonicalLink,
  } = seoPageData[pageKey] || defaultData;

  useEffect(() => {
    // Use a small delay to ensure Helmet has finished updating
    const timeoutId = setTimeout(() => {
      const helmetTitle = document.querySelector('title');
      const helmetMeta = document.querySelector('meta[property="og:title"]');

      // Only remove default tags if Helmet tags are actually present
      if (helmetTitle && helmetMeta) {
        const defaultMetaTags = document.querySelectorAll(
          'link[rel="canonical"], meta[name="description"], meta[property^="og:"], meta[property^="twitter:"]',
        );
        defaultMetaTags.forEach((tag) => {
          // Check if the tag wasn't added by Helmet
          if (!tag.getAttribute('data-rh')) {
            tag.parentNode.removeChild(tag);
          }
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);

      // Only restore default tags if the route has changed
      if (window.location.pathname !== initialPathRef.current) {
        // Remove any existing default tags first
        const existingDefaults = document.querySelectorAll(
          'link[rel="canonical"]:not([data-rh]), meta:not([data-rh])[property^="og:"], meta:not([data-rh])[property^="twitter:"], meta:not([data-rh])[name="description"], meta:not([data-rh])[name="title"]',
        );
        existingDefaults.forEach((tag) => tag.remove());

        // Insert new default tags
        const defaultMetaString = `
          <meta property="title" content="${defaultData.title}">
          <meta name="description" content="${defaultData.description}">
          <meta property="og:title" content="${defaultData.ogTitle}">
          <meta property="og:description" content="${defaultData.ogDescription}">
          <meta property="og:image" content="${defaultData.ogImage}">
          <meta property="og:url" content="${defaultData.ogUrl}">
          <meta name="twitter:card" content="summary_large_image">
          <meta property="twitter:title" content="${defaultData.ogTitle}">
          <meta property="twitter:description" content="${defaultData.ogDescription}">
          <meta property="twitter:image" content="${defaultData.ogImage}">
          <link rel="canonical" href="https://app.bluequbit.io/" />
        `;

        document.head.insertAdjacentHTML('afterbegin', defaultMetaString);
      }
    };
  }, [pageKey]); // Added pageKey as dependency

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogType && <meta property="og:type" content={ogType} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        <meta name="twitter:card" content="summary_large_image" />
        {ogTitle && <meta property="twitter:title" content={ogTitle} />}
        {ogDescription && <meta property="twitter:description" content={ogDescription} />}
        {ogImage && <meta property="twitter:image" content={ogImage} />}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};

export default withSeo;
