import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from 'components/system/ModalContext';
import { AuthForm } from 'components/pages/Auth';
import useQueryParams from 'hooks/useGetSearchParam';
import { auth, onAuthStateChanged, useAuthState } from 'api/services/firebase';

const withSignInDialog = (Component) => ({ ...props }) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext();
  const [isMobile, setIsMobile] = useState(false);
  const { useGetSearchParam, useRemoveSearchParam } = useQueryParams();

  const param = useGetSearchParam('open');
  const backUrl = useGetSearchParam('back');

  const [user] = useAuthState(auth);
  const isGuest = !user;

  const handleClose = useCallback(
    () => {
      useRemoveSearchParam('open');
      useRemoveSearchParam('back');
    },
    [useRemoveSearchParam],
  );

  const openSignInDialog = (props = {}) => {
    openModal({
      content: (
        <AuthForm
          view="signIn"
          handleSubmitCallback={closeModal}
          {...props}
        />

      ),
      onCloseCallback: handleClose,
    });
  };
  useEffect(() => {
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    if (param === 'login' && isGuest) {
      if (isMobile) {
        navigate(`/sign-in?back=${backUrl}`);
      } else openSignInDialog(true);
    }
    const unsub = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (isMobile && backUrl) {
          navigate(backUrl);
        } else closeModal();
      }
    });
    return unsub;
  }, [isGuest, param, isMobile]);

  return (

    <Component
      {...props}
      openSignInDialog={openSignInDialog}
    />
  );
};

export default withSignInDialog;
