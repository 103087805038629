import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

const Input = ({
  name, value, label, error, onChange, sx, labelSx = {}, multiline, startAdornment, inputType = 'input', ...rest
}) => (
  <Box sx={{ ...sx }}>
    <InputLabel
      shirnk="true"
      htmlFor={name}
    >
      <Typography
        sx={{
          color: error ? '#F44336' : '#A9B0BC',
          display: 'block',
          ...labelSx,
        }}
        variant="buttonSmall"
        htmlFor={name}
      >
        {label}
      </Typography>
    </InputLabel>
    {inputType === 'input' ? (
      <TextField
        fullWidth
        autoComplete="off"
        sx={{
          margin: 0,
          height: 'auto',
          '&:MuiInputBase-root': {
            height: 'fit-content',
          },
        }}
        margin="normal"
        error={!!error}
        multiline={multiline}
        value={value}
        id={name}
        name={name}
        helperText={error}
        onChange={onChange}
        InputProps={{
          startAdornment,
        }}
        {...rest}
      />
    ) : (
      <TextareaAutosize
        fullWidth
        autoComplete="off"
        margin="normal"
        error={!!error}
        multiline={multiline}
        value={value}
        id={name}
        name={name}
        helperText={error}
        onChange={onChange}
        InputProps={{
          startAdornment,
        }}
        {...rest}
      />
    )}
  </Box>
);
export default Input;
