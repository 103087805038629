import { useState, useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useAccountStore from 'store/accountStore';
import { getSavedAccountMode, saveAccountMode } from 'utils/helpers';
import { TEAM_PROFILE, USER_PROFILE } from 'utils/constants';
import { auth, useAuthState } from 'api/services/firebase';
import useSyncTabs from 'hooks/useSyncTabs';
import colors from 'styles/colors';

const styles = {
  labelActive: {
    color: colors.text.primary,
    fontSize: '18px',
    cursor: 'pointer',
  },
  label: {
    color: colors.text.secondary,
    fontSize: '16px',
    cursor: 'pointer',
  },
};

const AccountSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 18,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(14px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: 10,
    marginTop: 1,
    marginLeft: 1,
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: colors.primary.shade_50p,
    boxSizing: 'border-box',
  },
}));

const accountModeSwitcherOptions = {
  [USER_PROFILE]: TEAM_PROFILE,
  [TEAM_PROFILE]: USER_PROFILE,
};

const AccountModeSwitch = () => {
  const [accountMode, setAccountMode] = useState('user');
  const [user] = useAuthState(auth);
  const [query, setQuery] = useQueryParams({
    profile: StringParam,
  });

  const { profile } = query;

  const accountModesNumber = useAccountStore(
    (state) => state.account?.profiles?.length,
  );
  const setActiveAccount = useAccountStore((state) => state.setActiveAccount);

  const { isAccountAvailable, teamId, userId } = useAccountStore((state) => ({
    isAccountAvailable: !!state.account.userId,
    teamId: state.account.team?.teamId,
    userId: state.account.userId,
  }));

  useEffect(() => {
    if (user && profile) {
      setAccountMode(profile || getSavedAccountMode(userId) || USER_PROFILE);
      setActiveAccount(profile || getSavedAccountMode(userId) || USER_PROFILE);
      saveAccountMode(profile, userId, teamId);
    }
  }, [user, profile]);

  useEffect(() => {
    if (isAccountAvailable) {
      setAccountMode(profile || getSavedAccountMode(userId) || USER_PROFILE);
      setActiveAccount(profile || getSavedAccountMode(userId) || USER_PROFILE);

      if (profile) {
        saveAccountMode(profile, userId, teamId);
      }
    }
  }, [isAccountAvailable, profile]);

  useSyncTabs('accountMode_', (newMode) => {
    if (newMode?.role === USER_PROFILE || newMode?.role === TEAM_PROFILE) {
      setAccountMode(newMode.role);
      setActiveAccount(newMode.role);
    }
  });

  const handleChange = () => {
    // save to local storage
    saveAccountMode(accountModeSwitcherOptions[accountMode], userId, teamId);
    setAccountMode(accountModeSwitcherOptions[accountMode]); // update local state
    setActiveAccount(accountModeSwitcherOptions[accountMode]); // update active account
    setQuery({}, 'replace');
  };

  return accountModesNumber > 1 ? (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ height: '44px', paddingLeft: '24px', width: '102px' }}
    >
      <AccountSwitch
        checked={accountMode === TEAM_PROFILE}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'account mode' }}
      />
      <Typography
        onClick={handleChange}
        sx={accountMode === TEAM_PROFILE ? styles.labelActive : styles.label}
        color={colors.text.primary}
      >
        Team
      </Typography>
    </Stack>
    // eslint-disable-next-line react/jsx-no-useless-fragment
  ) : <span />;
};

export default AccountModeSwitch;
