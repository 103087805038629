import { useCallback, useEffect, useState } from 'react';
import { useModalContext } from 'components/system/ModalContext';
import { AuthForm } from 'components/pages/Auth';
import useQueryParams from 'hooks/useGetSearchParam';
import { auth, onAuthStateChanged, useAuthState } from 'api/services/firebase';
import { useNavigate } from 'react-router-dom';

const withSignUpDialog = (Component) => ({ ...props }) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext();
  const [isMobile, setIsMobile] = useState(false);
  const { useGetSearchParam, useRemoveSearchParam } = useQueryParams();

  const param = useGetSearchParam('open');
  const backUrl = useGetSearchParam('back');

  const [user] = useAuthState(auth);
  const isGuest = !user;

  const handleClose = useCallback(
    () => {
      useRemoveSearchParam('open');
      useRemoveSearchParam('back');
    },
    [useRemoveSearchParam],
  );

  const openSignUpDialog = () => {
    openModal({
      content: (
        <AuthForm
          view="signUp"
          handleSubmitCallback={closeModal}
        />
      ),
      onCloseCallback: handleClose,
    });
  };

  useEffect(() => {
    setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    if (param === 'sign-up' && isGuest) {
      if (isMobile) {
        navigate(`/sign-up?back=${backUrl}`);
      } else openSignUpDialog(true);
    }
    const unsub = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (isMobile && backUrl) {
          navigate(backUrl);
        } else closeModal();
      }
    });
    return unsub;
  }, [isGuest, param, isMobile]);
  return (

    <Component
      {...props}
      openSignUpDialog={openSignUpDialog}
    />
  );
};

export default withSignUpDialog;
