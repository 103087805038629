/**
 * useSeo - A custom hook to dynamically set SEO metadata and Open Graph tags.
 *
 * @param {string} title - The title of the page (used for the browser tab and Open Graph title).
 * @param {string} description - The meta description of the page (used for SEO and Open Graph description).
 * @param {string} [ogType] - The type of Open Graph object (e.g., "website", "article").
 * @param {string} [ogImage] - The URL of an image to represent the page (used in Open Graph for social media previews).
 * @param {string} [ogUrl] - The canonical URL of the page (used in Open Graph).
 *
 * @example
 * const SeoComponent = useSeo({
 *   title: "Homepage",
 *   description: "Welcome to the homepage of our amazing site!",
 *   ogType: "website",
 *   ogImage: "https://example.com/image.jpg",
 *   ogUrl: "https://example.com",
 * });
 */
export const defaultData = {
  title: 'BlueQubit | App',
  description: 'BlueQubit is the simplest way to interact with Quantum. Run quantum programs on simulators as well as connect to real quantum devices with 1-click.',
  ogType: 'website',
  ogTitle: 'BlueQubit | App',
  ogDescription: 'BlueQubit is the simplest way to interact with Quantum. Run quantum programs on simulators as well as connect to real quantum devices with 1-click.',
  ogImage: 'https://uploads-ssl.webflow.com/639a397b9711984d91a35d74/63f88e29c7b20c833f239cfc_bq_app_thumbnail.png',
  ogUrl: 'https://app.bluequbit.io', // Add this if you want a canonical URL
  canonicalLink: 'https://app.bluequbit.io',
};

export const DocsPage = {
  title: 'BlueQubit | Docs',
  description: 'Get Started with BlueQubit now. Check out our examples and docs on how to run simple quantum programs.',
  ogType: 'website',
  ogTitle: 'BlueQubit | Docs',
  ogDescription: 'Get Started with BlueQubit now. Check out our examples and docs on how to run simple quantum programs.',
  ogImage: 'https://uploads-ssl.webflow.com/639a397b9711984d91a35d74/63f88e29c7b20c833f239cfc_bq_app_thumbnail.png',
  ogUrl: 'https://app.bluequbit.io/docs',
  canonicalLink: 'https://app.bluequbit.io/docs',
};

export const JobsPage = {
  ...defaultData,
  title: 'BlueQubit | Jobs',
};

export const SignUpPage = {
  title: 'BlueQubit | Sign up',
  description: 'Sign up today for FREE on Bluequbit. Simplest way to connect to real quantum computers. Run your quantum programs in the multiverse!',
  ogType: 'website',
  ogTitle: 'BlueQubit | Sign up',
  ogDescription: 'Sign up today for FREE on Bluequbit. Simplest way to connect to real quantum computers. Run your quantum programs in the multiverse!',
  ogImage: 'https://uploads-ssl.webflow.com/639a397b9711984d91a35d74/63f88e29c7b20c833f239cfc_bq_app_thumbnail.png',
  ogUrl: 'https://app.bluequbit.io/sign-up',
  canonicalLink: 'https://app.bluequbit.io/sign-up',
};

export const HackathonPage = {
  title: 'BlueQubit | Hackathons',
  description: 'Join Bluequbit\'s quantum computing hackathon! Collaborate with innovative minds, solve real-world quantum challenges, and push the boundaries of quantum technology.',
  ogType: 'website',
  ogTitle: 'BlueQubit | Hackathons',
  ogDescription: 'Join Bluequbit\'s quantum computing hackathon! Collaborate with innovative minds, solve real-world quantum challenges, and push the boundaries of quantum technology.',
  ogImage: 'https://cdn.prod.website-files.com/639a397b9711984d91a35d74/67a6078628d0b9d78e4dbe7a_competitions_URL-preview.webp',
  ogUrl: 'https://app.bluequbit.io/hackathons',
  canonicalLink: 'https://app.bluequbit.io/hackathons',
};

export const breadcrumbSchema = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': 'https://app.bluequbit.io/sign-up',
        name: 'Sign Up',
      },
    },
    {
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': 'https://app.bluequbit.io/',
        name: 'Home',
      },
    },
    {
      '@type': 'ListItem',
      position: 3,
      item: {
        '@id': 'https://app.bluequbit.io/docs#a_b',
        name: 'Get Started with A+B',
      },
    },
    {
      '@type': 'ListItem',
      position: 4,
      item: {
        '@id': 'https://app.bluequbit.io/docs',
        name: 'Docs',
      },
    },
  ],
};

const seoPageData = {
  default: defaultData,
  DocsPage,
  SignUpPage,
  JobsPage,
  HackathonPage,
};
export default seoPageData;
