import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  auth,
  applyActionCode,
  signInWithEmailLink,
  confirmPasswordReset,
  useAuthState, sendSignInLinkToEmail,
} from 'api/services/firebase';
import Loading from 'components/system/Loading';
import Page from 'components/system/Page';
import colors from 'styles/colors';
import { ResetPassword } from './PasswordRecovery';

const AuthStatusManager = ({ isMobile }) => {
  const [searchParams] = useSearchParams();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const mode = searchParams.get('mode');
  const actionCode = searchParams.get('oobCode');
  const continueUrl = searchParams.get('continueUrl');

  const getRelativePath = (urlString) => {
    try {
      const url = new URL(urlString);
      return url.pathname + url.search;
    } catch (error) {
      console.error('Error getting relative path:', error);
      return urlString;
    }
  };

  const extractEmailFromContinueUrl = useCallback((urlString) => {
    try {
      const url = new URL(urlString);
      const email = url.searchParams.get('email');
      const decodedEmail = email ? decodeURIComponent(email).replace(/ /g, '+') : null;
      console.log('Extracted Email:', decodedEmail);
      return decodedEmail;
    } catch (error) {
      console.error('Error extracting email:', error);
      return null;
    }
  }, []);

  const navigateToAuth = (mobilePath, path) => {
    const relativePath = continueUrl ? getRelativePath(continueUrl) : '/';
    navigate(isMobile ? `/${mobilePath || 'sign-up'}?back=${relativePath}` : `/?open=${path || 'sign-up'}&back=${relativePath}`);
  };
  const getCorrectUrl = (url) => {
    if (process.env.ENVIRONMENT === 'development') {
      return url.replace('http://localhost:8080', 'https://app.bluequbit.io');
    }
    return url;
  };

  const fixedUrl = getCorrectUrl(window.location.href);

  const handleVerifyEmail = useCallback(async () => {
    try {
      const relativePath = continueUrl ? getRelativePath(continueUrl) : '/';
      await applyActionCode(auth, actionCode);

      console.log('Verifying email...');
      if (auth?.currentUser && !auth.currentUser.emailVerified) {
        await auth.currentUser.reload();
        await new Promise((resolve) => { setTimeout(resolve, 500); }); // Ensuring Firebase updates
        console.log('Email verification complete.');
        navigate(relativePath);
      } else {
        navigateToAuth('sign-in', 'login');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      navigateToAuth();
    }
  }, [actionCode, continueUrl, navigate]);

  const handleSignInWithEmail = useCallback(async () => {
    try {
      console.log('Attempting to sign in...');
      const relativePath = continueUrl ? getRelativePath(continueUrl) : '/';
      const email = extractEmailFromContinueUrl(continueUrl);

      if (!email) {
        console.error('Email not found in continueUrl.');
        navigateToAuth();
        return;
      }

      if (mode !== 'signIn') {
        const actionCodeSettings = {
          url: continueUrl,
          handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        navigateToAuth();
      }

      await signInWithEmailLink(auth, email, fixedUrl);
      console.log('Signed in successfully.');
      navigate(relativePath);
    } catch (error) {
      console.error('Error signing in with email link:', error);
      navigateToAuth();
    }
  }, [continueUrl, extractEmailFromContinueUrl, fixedUrl, mode, navigate]);

  const handleResetPassword = async (newPassword) => {
    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
      return { success: true };
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error.code === 'auth/invalid-action-code') {
        return {
          errors: {
            password: 'The link for password recovery has expired.',
          },
        };
      }
      return { error: error.message };
    }
  };

  useEffect(() => {
    if (!actionCode || loading) return undefined; // Avoid running if loading or no actionCode

    const handleAuthChange = async () => {
      if (mode === 'verifyEmail') {
        await handleVerifyEmail();
      } else if (mode === 'signIn') {
        await handleSignInWithEmail();
      }
    };

    const unsubscribe = auth.onAuthStateChanged(handleAuthChange);

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [mode, actionCode, loading, handleVerifyEmail, handleSignInWithEmail]);

  if (mode === 'resetPassword') {
    return <ResetPassword recoverPassword={(password) => handleResetPassword(password)} />;
  }

  if (mode === 'verifyEmail') {
    return <div>Verifying your email...</div>;
  }

  if (mode === 'signIn') {
    return <div>Verifying your email...</div>;
  }

  if (mode === 'verifyEmail' && !loading && !user) {
    return <div>Verifying your email...</div>;
  }

  return isMobile ? (
    <Page
      sx={{ backgroundColor: colors.background.main, height: '100%' }}
    >
      Loading
      {' '}
      <Loading />
    </Page>
  ) : null;
};

export default AuthStatusManager;
