import { createTheme } from '@mui/material/styles';
import typography from './typography';
import colors from './colors';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#0060FA',
      dark: '#0037C6',
      light: '#6A8DFF',
    },
    success: {
      main: '#66BB6A',
      dark: '#388E3C',
      light: '#81C784',
    },
    error: {
      main: '#F44336',
      dark: '#D32F2F',
      light: '#E57373',
    },
    custom: {
      dark: '#182341',
      light: '#FFFFFF',
    },
    background: '#060E1F',
    text: {
      primary: '#F2F5FA',
      secondary: '#A9B0BC',
    },
  },
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: colors.text.primary,
          textTransform: 'none',
          borderRadius: '100px',
          boxShadow: 'none',
          ...(ownerState.variant === 'contained' && ownerState.type === 'primary' && {
            background: colors.primary.main,
            fontSize: '16px',
            padding: '12px 24px',
            '&:hover': {
              background: colors.primary.light,
            },
            '&:active': {
              background: colors.primary.dark,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.type === 'secondary' && {
            background: colors.background.paper_11p,
            padding: '10px 20px',
            '&:hover': {
              background: colors.background.paper_12p,
            },
            '&:active': {
              background: colors.background.paper_9p,
            },
            textDecoration: 'none',
            ...(ownerState.disabled === true && {
              color: 'rgba(242, 245, 250, 0.5) !important',
              textDecoration: 'none',
            }),
          }),
          ...(ownerState.variant === 'outlined' && ownerState.type === 'secondary' && {
            border: `1px solid ${colors.primary.main}`,
            fontSize: '12px',
            padding: '10px 20px',
            '&:hover': {
              border: `1px solid ${colors.primary.light}`,
            },
            '&:active': {
              border: `1px solid ${colors.primary.dark}`,
            },
          }),
          ...(ownerState.disabled && {
            color: `${colors.text.disabled} !important`,
            backgroundColor: `${colors.primary.shade_16p} !important`,
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: (ownerState) => ({
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #060E1F',
          borderColor: 'red',
          borderRadius: '12px',
          color: colors.text.primary,
          height: ownerState.ownerState.rows ? 'auto' : '38px',
          minHeight: '38px',
          fontSize: '14px',
          lineHeight: '18px',
          ...(ownerState.ownerState.error && {
            borderColor: colors.error.main,
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: (ownerState) => ({
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #060E1F',
          color: colors.text.primary,
          ...(ownerState.error && {
            borderColor: colors.error.main,
          }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: (ownerState) => ({
          borderColor: 'red',
          borderRadius: '12px',
          ...(ownerState.error && {
            borderColor: colors.error.main,
          }),
        }),
        notchedOutline: () => ({
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '12px',
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => ({
          color: '#687487',
          top: '-5px',
          fontSize: '14px',
          lineHeight: '18px',
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: () => ({
          background: 'rgba(6, 14, 31, 0.5)',
          backdropFilter: 'blur(10px)',
        }),
        paper: () => ({
          background: colors.background.paper_5p,
          boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
          borderRadius: '24px',
          padding: '32px',
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: () => ({
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #060E1F',
          borderRadius: '16px',
        }),
        root: () => ({
          padding: '8px',
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: () => ({
          borderColor: colors.border.border_5p,
          color: colors.text.primary,
          ...typography.helper,
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: () => ({
          '& th': {
            color: colors.text.tetriary,
            ...typography.cell,
            textTransform: 'uppercase',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: (ownerState) => ({
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #060E1F',
          borderColor: 'red',
          borderRadius: '12px',
          color: colors.text.primary,
          height: ownerState.rows ? 'auto' : '38px',
          minHeight: '38px',
          fontSize: '14px',
          lineHeight: '18px',
          ...(ownerState.error && {
            borderColor: colors.error.main,
          }),
        }),
      },
    },
  },
});

export default customTheme;
