import { useEffect } from 'react';

const useSyncTabs = (keyPrefix, onChange) => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key && event.key.startsWith(keyPrefix)) {
        try {
          const newValue = JSON.parse(event.newValue);
          onChange(newValue, event);
        } catch (err) {
          console.warn(`Failed to parse storage key "${event.key}":`, err);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [keyPrefix, onChange]);
};

export default useSyncTabs;
